import { useState, useEffect } from 'react';
import Popover from 'design-system/src/ui/Popover/Popover';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '@tolgee/react';
import { store_set } from '/src/store.js';
import { useStore } from '/src/store.js';
import axiosInstance from '../../request_manager.js';
import { useReverseTranslate } from '/src/language_manager.jsx';
import './Sidebar.css';

const RENDER_ON = [
  /^.*\/dashboard.*$/,
  /^.*\/users.*$/,
  /^.*\/projects.*$/,
  /^.*\/home.*$/,
  /^.*\/classification-page.*$/,
  /^.*\/agents.*$/,
  /^.*\/agent.*$/,
  /^.*\/connectors.*$/,
  /^.*\/connector.*$/,
  /^.*\/tasks.*$/,
];

const shouldRenderSidebar = () => {
  return RENDER_ON.some((regex) => regex.test(location.pathname));
};

function SidebarSubMenu({ text, icon, items }) {
  const [showDropDown, setShowDropDown] = useState(false);
  const navigate = useNavigate();
  const { rt } = useReverseTranslate();

  const triggerNavigate = (to) => {
    setShowDropDown(false);
    setTimeout(() => {
      navigate(to);
    }, 300);
  };

  return (
    <>
      <div
        className={`sidebar-item ${showDropDown ? 'active' : ''}`}
        onClick={() => setShowDropDown(!showDropDown)}
      >
        <span className='sidebar-dropdown-container'>
          <span className='sidebar-dropdown-container-left' href=''>
            <span className='sidebar-dropdown-icon-1-container'>
              <span className='material-icons sidebar-left-icon-dropdown-title'>
                {icon}
              </span>
            </span>
            <span className='sidebar-dropdown-title-text sidebar-text-ellipsis item'>
              {text}
            </span>
          </span>
          <span className='sidebar-dropdown-icon-2-container'>
            <span className={'material-icons sidebar-dropdown-icon-2'}>
              {showDropDown ? 'expand_less' : 'expand_more'}
            </span>
          </span>
        </span>
      </div>
      {showDropDown && (
        <>
          {items.map((item) => (
            <div
              key={item.url}
              className='sidebar-item sidebar-item-link-1-container'
              onClick={() => triggerNavigate(item.url)}
            >
              <span className='sidebar-dropdown-item' href='#'>
                <span
                  id='dropdown-icon'
                  className='material-icons sidebar-left-icon'
                >
                  {item.icon}
                </span>
                <span
                  id='dropdown-item-text'
                  className='sidebar-text sidebar-text-ellipsis'
                >
                  {rt(item.text)}
                </span>
              </span>
            </div>
          ))}
        </>
      )}
    </>
  );
}

function Sidebar() {
  const [shouldRender, setShouldRender] = useState(false);
  const navigate = useNavigate();
  const location = useStore('location');
  const first_name = useStore('first_name');
  const last_name = useStore('last_name');
  const email = useStore('email');
  const permissions = useStore('permissions');
  const { t } = useTranslate();
  const { rt } = useReverseTranslate();
  // Logout function

  const logout = () => {
    store_set('token', false);
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const clearData = () => {
    store_set('full_store_reset', true);
    axiosInstance
      .get('clear-cache/')
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const triggerNavigate = (to) => {
    setTimeout(() => {
      navigate(to);
    }, 300);
  };

  useEffect(() => {
    const shouldRenderOnNewPage = shouldRenderSidebar(location);
    setShouldRender(shouldRenderOnNewPage);
  }, [location]);

  const items = [
    { name: 'Profile', action: () => console.log('Profile') },
    { name: 'Clear Data', action: () => clearData() },
    { name: 'Logout', action: () => logout() },
  ];

  return (
    <>
      {shouldRender && (
        <div className='sidebar-container'>
          <div className='sidebar-image-logo-container'>
            <img
              className='sidebar-image-logo'
              src='/assets/enlaight-logo-white.svg'
              width='260px'
              alt='logo'
              onClick={() => triggerNavigate('/home')}
            />
          </div>
          <div className='sidebar'>
            <div
              className='sidebar-item'
              onClick={() => triggerNavigate('/home')}
            >
              <span className='sidebar-home-container'>
                <span className='sidebar-home-icon-container'>
                  <span className='material-icons sidebar-left-icon'>home</span>
                </span>
                <span className='sidebar-home-text-container sidebar-text-ellipsis'>
                  {' '}
                  {t('sidebar_home', 'Home')}
                </span>
              </span>
            </div>

            <div
              className='sidebar-item'
              onClick={() => triggerNavigate('/classification-page')}
            >
              <span className='sidebar-classification-container'>
                <span className='sidebar-classification-icon-container'>
                  <span className='material-icons sidebar-left-icon'>
                    reviews
                  </span>
                </span>
                <span className='sidebar-classification-text-container sidebar-text-ellipsis'>
                  {rt('Classification Review')}
                </span>
              </span>
            </div>
            {permissions && permissions.includes('admins') && (
              <SidebarSubMenu
                text={rt('Manage Users')}
                icon='manage_accounts'
                items={[
                  {
                    url: '/users',
                    icon: 'group',
                    text: t('sidebar_users', 'Users'),
                  },
                  {
                    url: '/projects',
                    icon: 'dvr',
                    text: t('sidebar_projects', 'Projects'),
                  },
                ]}
              />
            )}
            {permissions && permissions.includes('admins') && (
              <div
                className='sidebar-item'
                onClick={() => triggerNavigate('/connectors')}
              >
                <div className='sidebar-logout-container'>
                  <span className='sidebar-logout-icon-container'>
                    <span className='material-icons sidebar-left-icon'>
                      cable
                    </span>
                  </span>
                  <span className='sidebar-sidebar-text logout sidebar-text-ellipsis'>
                    {rt('Connectors')}
                  </span>
                </div>
              </div>
            )}
            {permissions && permissions.includes('admins') && (
              <div
                className='sidebar-item'
                onClick={() => triggerNavigate('/tasks')}
              >
                <div className='sidebar-logout-container'>
                  <span className='sidebar-logout-icon-container'>
                    <span className='material-icons sidebar-left-icon'>
                      history
                    </span>
                  </span>
                  <span className='sidebar-sidebar-text logout sidebar-text-ellipsis'>
                    {rt('Task History')}
                  </span>
                </div>
              </div>
            )}
            {permissions && permissions.includes('admins') && (
              <div
                className='sidebar-item'
                onClick={() => triggerNavigate('/agents')}
              >
                <div className='sidebar-logout-container'>
                  <span className='sidebar-logout-icon-container'>
                    <span className='material-icons sidebar-left-icon'>
                      smart_toy
                    </span>
                  </span>
                  <span className='sidebar-sidebar-text logout sidebar-text-ellipsis'>
                    {rt('Agents')}
                  </span>
                </div>
              </div>
            )}
            <div className='sidebar-item' onClick={logout}>
              <div className='sidebar-logout-container'>
                <span className='sidebar-logout-icon-container'>
                  <span className='material-icons sidebar-left-icon'>
                    logout
                  </span>
                </span>
                <span className='sidebar-sidebar-text logout sidebar-text-ellipsis'>
                  {rt('Logout')}
                </span>
              </div>
            </div>
          </div>
          <div className='sidebar-footer'>
            <div className='sidebar-user-info-container'>
              <div className='sidebar-user-info-container-left'>
                <span className='sidebar-avatar-icon-container'>
                  <span className='material-icons avatar'>account_circle</span>
                </span>
                <span className='sidebar-user-info'>
                  <span className='sidebar-user-name-container'>
                    <div className='sidebar-user-name'>
                      {first_name} {last_name}
                    </div>
                  </span>
                  <span className='sidebar-user-email-container'>
                    <div className='sidebar-user-email'>{email}</div>
                  </span>
                </span>
              </div>
              <div className='sidebar-more-icon-popover-container'>
                <Popover
                  placement='right'
                  content={items}
                  trigger='click'
                  color='white'
                >
                  <span className='material-icons sidebar-more-icon-container'>
                    more_vert
                  </span>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Sidebar;
export { shouldRenderSidebar };

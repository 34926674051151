import { useState } from 'react';
import { Popover as PopoverAntd, Button } from 'antd';
import './Popover.css';

const Popover = ({
  placement,
  content,
  trigger,
  color,
  children,
  ...props
}) => {
  const [contentIndex, setContentIndex] = useState(null);

  const handleClick = (index, action) => {
    setContentIndex(index);
    if (action) {
      action();
    }
  };

  const popoverContent = (content) => {
    return content.map((item, index) => (
      <div
        key={index}
        className='storybook-popover-item-container'
        onClick={() => handleClick(index, item.action)}
      >
        <div className='storybook-popover-item-text'>{item.name}</div>
      </div>
    ));
  };

  return (
    <PopoverAntd
      overlayClassName='storybook-popover'
      placement={placement}
      content={popoverContent(content)}
      trigger={trigger}
      color={color}
      arrow={false}
      {...props}
    >
      {children || <Button>Open Popover</Button>}
    </PopoverAntd>
  );
};

export default Popover;
